import React from 'react';
import BlogEditor from './BlogEditor';
import './BlogMain.css';

const BlogMain = () => {
    return (
        <div className="blog-main">
            <h1>Blogs Coming Soon...</h1>
            {/* <BlogEditor /> */}
        </div>
    );
};

export default BlogMain;