import React from 'react';

const BlogAdmin = () =>
{
    
    return (
        <div>
            <h1>Blog Admin</h1>
            <h1>Blog Admin</h1>
        </div>
    );
};

export default BlogAdmin;